import { useReducer } from "react"
import { smsErrors, onFail, onRule, onStatusError } from "../../../UI/PopUp"
import Swal from "sweetalert2/dist/sweetalert2.js"

import formActions from "./actions"

import axios from "axios"

import {
  validateName,
  validatePhoneNumber,
  transformPhoneAfterMask,
  validateInn,
  validateCompanyName,
  validateCode,
} from "./validation"
import { getUrlParams } from "./params"

const {
  SEND_SMS,
  START_FETCH,
  FINISH_FETCH,
  FETCH_SUCCESS,
  FETCH_FAIL,
  CHANGE_NAME,
  CHANGE_INN,
  VALID_INN,
  CHECKED_INN,
  VALID_NAME,
  CHANGE_PHONE,
  VALID_PHONE,
  CHECKED_NAME,
  CHECKED_PHONE,
  TOGGLE_RULE,
  CHANGE_COMPANY_NAME,
  VALID_COMPANY_NAME,
  CHECKED_COMPANY_NAME,
} = formActions

const initialState = {
  fetch: false,
  sendSMS: false,
  success: false,
  fail: false,
  name: { valid: false, value: "", checked: false },
  phone: { valid: false, value: "", checked: false },
  inn: { valid: false, value: "", checked: false },
  companyName: { valid: false, value: "", checked: false },
  rule: true,
}

const checkChannel = () => {
  const { utm_source } = getUrlParams()
  //console.log("utm_source", utm_source)

  return utm_source
}

const checkMedium = () => {
  const { utm_medium } = getUrlParams()
  //console.log("utm_medium", utm_medium)

  return utm_medium
}

const checkСampaign = () => {
  const { utm_campaign } = getUrlParams()
  //console.log("utm_campaign", utm_campaign)

  return utm_campaign
}

const checkСontent = () => {
  const { utm_content } = getUrlParams()
  //console.log("utm_campaign", utm_campaign)

  return utm_content
}

const checkTerm = () => {
  const { utm_term } = getUrlParams()
  //console.log("utm_campaign", utm_campaign)

  return utm_term
}

const reducer = (state, action) => {
  switch (action.type) {
    case SEND_SMS:
      return { ...state, sendSMS: action.payload }
    case START_FETCH:
      return { ...state, fetch: true }
    case FINISH_FETCH:
      return { ...state, ...initialState }
    case FETCH_SUCCESS:
      return { ...state, fetch: false, success: true }
    case FETCH_FAIL:
      return { ...state, fetch: false, fail: true }

    case CHANGE_NAME:
      return { ...state, name: { ...state.name, value: action.payload } }
    case VALID_NAME:
      return {
        ...state,
        name: { ...state.name, valid: action.payload },
      }

    case CHANGE_INN:
      return { ...state, inn: { ...state.inn, value: action.payload } }
    case VALID_INN:
      return { ...state, inn: { ...state.inn, valid: action.payload } }
    case CHECKED_INN:
      return { ...state, inn: { ...state.inn, checked: true } }

    case CHECKED_NAME:
      return { ...state, name: { ...state.name, checked: true } }
    case CHECKED_PHONE:
      return { ...state, phone: { ...state.phone, checked: true } }

    case CHANGE_PHONE:
      return { ...state, phone: { ...state.phone, value: action.payload } }
    case VALID_PHONE:
      return {
        ...state,
        phone: { ...state.phone, valid: action.payload },
      }
    case CHANGE_COMPANY_NAME:
      return {
        ...state,
        companyName: { ...state.companyName, value: action.payload },
      }
    case VALID_COMPANY_NAME:
      return {
        ...state,
        companyName: { ...state.companyName, valid: action.payload },
      }
    case CHECKED_COMPANY_NAME:
      return { ...state, companyName: { ...state.companyName, checked: true } }

    case TOGGLE_RULE:
      return {
        ...state,
        rule: !state.rule,
      }

    default:
      return state
  }
}

const useForm = link => {
  const [state, dispatch] = useReducer(reducer, initialState)

  //UI
  const startSendSms = value => dispatch({ type: SEND_SMS, payload: value })

  const startFetch = () => dispatch({ type: START_FETCH })
  const finishFetch = () => dispatch({ type: FINISH_FETCH })
  const fetchSuccess = () => dispatch({ type: FETCH_SUCCESS })
  const fetchFail = () => dispatch({ type: FETCH_FAIL })

  const toggleRule = () => dispatch({ type: TOGGLE_RULE })

  //Name
  const checkedName = () => dispatch({ type: CHECKED_NAME })

  const validName = isValidName =>
    dispatch({ type: VALID_NAME, payload: isValidName })
  const changeName = name => {
    dispatch({ type: CHANGE_NAME, payload: name })
    const isValidName = validateName(name)

    validName(isValidName)
  }

  //INN
  const checkedInn = () => dispatch({ type: CHECKED_INN })

  const validInn = isValidInn =>
    dispatch({ type: VALID_INN, payload: isValidInn })
  const changeInn = inn => {
    dispatch({ type: CHANGE_INN, payload: inn })

    const isValidInn = validateInn(inn)

    validInn(isValidInn)
  }

  //Phone
  const checkedPhone = () => dispatch({ type: CHECKED_PHONE })

  const validPhone = isValidPhone =>
    dispatch({ type: VALID_PHONE, payload: isValidPhone })
  const changePhone = phone => {
    dispatch({ type: CHANGE_PHONE, payload: phone })
    const normalPhone = transformPhoneAfterMask(phone)

    const isValidPhone = validatePhoneNumber(normalPhone)
    validPhone(isValidPhone)
  }

  // Company Name
  const checkedCompanyName = () => dispatch({ type: CHECKED_COMPANY_NAME })

  const validCompanyName = isValidCompanyName =>
    dispatch({ type: VALID_COMPANY_NAME, payload: isValidCompanyName })
  const changeCompanyName = companyName => {
    dispatch({ type: CHANGE_COMPANY_NAME, payload: companyName })
    const isValidCompanyName = validateCompanyName(companyName)

    validCompanyName(isValidCompanyName)
  }

  const onSend = async () => {
    try {
      const { phone, inn, name, companyName, rule } = state

      ///Transform form after mask
      const normalPhone = transformPhoneAfterMask(phone.value)

      //Validate fields
      const isValidPhone = validatePhoneNumber(normalPhone)
      const isValidName = validateName(name.value)
      const isValidInn = validateInn(inn.value)
      const isValidCompanyName = validateCompanyName(companyName.value)

      checkedPhone()
      checkedInn()
      checkedName()
      checkedCompanyName()

      //Set fields
      validPhone(isValidPhone)
      validName(isValidName)
      validInn(isValidInn)
      validCompanyName(isValidCompanyName)

      if (isValidPhone && isValidName && isValidInn && isValidCompanyName) {
        if (!rule) {
          onRule()
          return
        }

        const phoneFormated = phone.value.replace(/[\s()-]/gi, "")

        if (
          phoneFormated === "+380500000000" ||
          phoneFormated === "+380957027817"
        ) {
          sendDataToServer()
        } else {
          send_status_to_db(
            normalPhone,
            name.value,
            inn.value,
            "Cash landing Plus",
            "FAILED"
          )

          startSendSms(true)
        }
      }
    } catch (error) {
      console.log("err", error)
    }
  }

  const sendDataToServer = async () => {
    const { phone, inn, name, rule, companyName } = state
    const normalPhone = transformPhoneAfterMask(phone.value)
    const utmSource = checkChannel()
    const utmMedium = checkMedium()
    const utmСampaign = checkСampaign()
    const utmContent = checkСontent()
    const utmTerm = checkTerm()
    startFetch()
    try {
      const res = await axios.post(
        "https://refinance.tascombank.ua/api/watermelon",
        {
          name: name.value,
          phone: normalPhone,
          inn: inn.value ? inn.value : "none",
          company_name: companyName.value ? companyName.value : "none",
          content: utmContent ? utmContent : "landing",
          source: utmSource ? utmSource : "landing",
          medium: utmMedium ? utmMedium : "landing",
          campaign: utmСampaign ? utmСampaign : "landing",
          term: utmTerm ? utmTerm : "landing",
        }
      )
      if (res.status === 201 || res.status == 200) {
        await send_status_to_db(
          normalPhone,
          name.value,
          inn.value,
          "Cash landing Plus",
          "SUCCESS"
        )

        window.location.href = "https://cash-plus.tascombank.ua/typ"
        finishFetch()
      } else {
        fetchFail()

        send_status_to_db(
          normalPhone,
          name.value,
          inn.value,
          "Cash landing Plus",
          "FAILED"
        )
      }
    } catch (error) {
      console.log("error", error)
      fetchFail()
      send_status_to_db(
        normalPhone,
        name.value,
        inn.value,
        "Cash landing Plus",
        "FAILED"
      )
    } finally {
      finishFetch()
      return
    }
  }

  const sendSMSReq = async () => {
    const smsLink = "https://tascombank.ua/api/sms/send"
    const normalPhone = transformPhoneAfterMask(state.phone.value)

    return await axios
      .post(smsLink, { phoneNumber: normalPhone, source: "cash-plus" })
      .then(response => {
        return response.data.timeout ? response.data.timeout : null
      })
      .catch(error => {
        if (error.response && smsErrors.hasOwnProperty(error.response.status)) {
          if (error.response.status == 452) {
            send_status_to_db(
              normalPhone,
              state.name.value,
              state.inn.value,
              "Cash landing Plus",
              "REPEATED"
            )
          }
          onStatusError(state.phone.value, error.response.status, smsContext)
        } else {
          onFail()
          send_status_to_db(
            normalPhone,
            state.name.value,
            state.inn.value,
            "Cash landing Plus",
            "FAILED"
          )
        }
      })
  }

  const reSendSmsReq = async () => {
    const smsLinkReSend = "https://tascombank.ua/api/sms/resend"
    const normalPhone = transformPhoneAfterMask(state.phone.value)

    return await axios
      .post(smsLinkReSend, { phoneNumber: normalPhone, source: "cash-plus" })
      .then(() => {
        return true
      })
      .catch(error => {
        if (error.response && smsErrors.hasOwnProperty(error.response.status)) {
          if (error.response.status === 454) {
            send_status_to_db(
              normalPhone,
              state.name.value,
              state.inn.value,
              "Cash landing Plus",
              "REPEATED"
            )
            onStatusError(state.phone.value, 454, smsContext)
          } else {
            onStatusError(state.phone.value, error.response.status, smsContext)
          }
        } else {
          onFail()
          send_status_to_db(
            normalPhone,
            state.name.value,
            state.inn.value,
            "Cash landing Plus",
            "FAILED"
          )
        }
      })
  }

  const confirmSmsReq = async code => {
    const smsLinkConfirm = "https://tascombank.ua/api/sms/confirm"
    const normalPhone = transformPhoneAfterMask(state.phone.value)

    if (!validateCode(code)) {
      onStatusError(state.phone.value, 453, smsContext)
    } else {
      return await axios
        .post(smsLinkConfirm, {
          code,
          phoneNumber: normalPhone,
          source: "cash-plus",
        })
        .then(() => {
          sendDataToServer()
        })
        .catch(error => {
          if (
            error.response &&
            smsErrors.hasOwnProperty(error.response.status)
          ) {
            Swal.showValidationMessage(smsErrors[error.response.status])
            send_status_to_db(
              normalPhone,
              name.value,
              state.inn.value,
              "Cash landing Plus",
              "FAILED"
            )
          } else {
            onFail()
            send_status_to_db(
              normalPhone,
              state.name.value,
              state.inn.value,
              "Cash landing Plus",
              "FAILED"
            )
          }
        })
        .finally(() => {
          channel_landing_otp(normalPhone)
        })
    }
  }

  const channel_landing_otp = async (phone = null) => {
    try {
      if (!phone) {
        console.error("channel_landing_otp error: Phone is not exist")
        return
      }

      const data = {
        channel: "cash-plus",
        mobilePhone: phone,
      }

      await axios.post(
        "https://forms.tascombank.ua/api/v1/lending-channel",
        data
      )
    } catch (err) {
      console.error("Error channel_landing_otp", err)
    }
  }

  const log_form_submit = async () => {
    const { phone, name } = state

    try {
      if (!phone) {
        console.error("log_form_submit error: Phone is not exist")
        return
      }

      const data = {
        source: "cash-plus",
        phone: phone.value,
        name: name.value || "No name",
      }

      await axios.post("https://tascombank.ua/api/form-log", data)
    } catch (err) {
      console.error("Error log_form_submit", err)
    }
  }

  const send_status_to_db = async (
    phone = "",
    name = "",
    inn = "",
    source = "Cash landing Plus",
    status = "FAILED"
  ) => {
    try {
      if (!name) {
        console.error("send_status_to_db error: Name is not exist")
        return
      }

      if (!phone) {
        console.error("send_status_to_db error: Phone is not exist")
        return
      }

      if (!source) {
        console.error("send_status_to_db error: Source is not exist")
        return
      }

      const utmMedium = checkMedium()
      const utmСampaign = checkСampaign()
      const utmSource = checkChannel()

      const data = {
        source: "Cash landing Plus",
        phone: phone,
        name: name,
        inn: inn.toString(),
        status: status,
        utm_campaign: utmСampaign ? utmСampaign : "Cash landing Plus",
        utm_medium: utmMedium ? utmMedium : "Cash landing Plus",
        utm_source: utmSource ? utmSource : "Cash landing Plus",
        date: Date.now(),
      }

      await axios.post("https://tascombank.ua/api/form-log", data)
    } catch (err) {
      console.error("Error send_status_to_db", err)
    }
  }

  const smsContext = {
    startSendSms,
    sendSMSReq,
    reSendSmsReq,
    confirmSmsReq,
  }

  return {
    state,
    smsContext,
    changeName,
    checkedName,
    toggleRule,
    changeInn,
    checkedInn,
    checkedPhone,
    changePhone,
    changeCompanyName,
    checkedCompanyName,
    onSend,
  }
}

export default useForm
